
import { defineComponent } from 'vue';
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import { useRepositories } from '@/repository/repositories';
import { authState } from '@/store/auth_state_store';

export default defineComponent({
  name: "user-scaffold",
  components: { Header, Footer },

  setup() {
    const repos = useRepositories();
    return {
      repos,
    }
  },

  computed: {
    showLogout(): boolean {
      return (authState?.auth ?? false) && this.$route.path === "/";
    }
  },

  methods: {
    async onClickLogout(): Promise<void> {
      console.log("logout")
      await this.repos.token.delete()
      if (authState !== undefined) authState.auth = false;
      this.$router.push({path: "/login"});
      return;
    }
  },

});
