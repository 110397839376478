import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b437a8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-scaffold" }
const _hoisted_2 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header, {
      class: "header",
      showLogout: _ctx.showLogout,
      onClickLogout: _ctx.onClickLogout
    }, null, 8, ["showLogout", "onClickLogout"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view),
      _createVNode(_component_Footer, { class: "footer" })
    ])
  ]))
}